/* eslint-disable jsx-a11y/role-has-required-aria-props */
import React from "react";
import styled from "styled-components";
import breakpoints from "../helpers/styles/breakpoints";
import typography from "../helpers/styles/typography";
import spacing from "../helpers/styles/spacing";
import colors from "../helpers/styles/colors";
import { hasWindow } from "../helpers/window";
import { buttonStyle } from "../helpers/styles/buttonStyle";

const ColumnsLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
  justify-content: center;

  @media ${breakpoints.maxWidth650px} {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font: ${typography.h3Desktop};
`;

const Text = styled.div`
  font: ${typography.contentSmall};
  min-height: 50px;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  padding: ${spacing.gutter30} 0;
  margin-top: 16px;
  text-align: center;
  align-self: center;
  @media ${breakpoints.tablet} {
    padding: 0;
    text-align: left;
  }
`;

const ContactUsButton = styled.button`
  cursor: pointer;
  border-radius: 99px !important;
  padding: 10px 40px !important;
  width: 236px;
  height: 42px;
  margin: 0;
  box-sizing: border-box;
  ${buttonStyle()}
  border: 1px solid ${colors.dustyBlue};
  a {
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    text-decoration: none;
  }
`;

const GetTheApp = () => {
  const openChatHandler = () => {
    hasWindow() && window.genesysOpen();
  };

  return (
    <div>
      <ColumnsLayout>
        <Title>What if I don't have a smart meter?</Title>
        <Text>
          You&apos;ll need a compatible smart meter to use our app. If you haven&apos;t got one,
          talk to an agent to book a free installation.
        </Text>
        <ButtonWrapper>
          <ContactUsButton onClick={openChatHandler}>Contact us</ContactUsButton>
        </ButtonWrapper>
      </ColumnsLayout>
    </div>
  );
};

GetTheApp.propTypes = {};

export { GetTheApp };
