import React from "react";
import styled from "styled-components";
import colors from "../helpers/styles/colors";
import spacing from "../helpers/styles/spacing";
import { PageContainer } from "./PageContainer";
import ReactMarkdown from "react-markdown";
import { LinkStyle } from "../helpers/styles/LinkStyle";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import { ButtonWhiteBlue } from "./ButtonLinks";

const Container = styled(PageContainer)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const TextContainer = styled.div`
  display: flex;
  align-self: center;
  border: 1px solid ${colors.black};
  padding: ${spacing.gutter20};
  text-align: left;
  flex-direction: column;
`;

const Textbox = styled(ReactMarkdown)`
  a {
    ${LinkStyle()}
  }
`;

export const InlineBanner = ({ children }) => {
  return (
    <Container>
      <TextContainer>
        <Textbox rehypePlugins={[rehypeRaw, rehypeSanitize]} children={children}></Textbox>
        <ButtonWhiteBlue href={"/pricecap"}>Learn more</ButtonWhiteBlue>
      </TextContainer>
    </Container>
  );
};
