import React from "react";
import TrustPilotScore from "./TrustPilotScore";
import TrustPilotSlide from "./TrustPilotSlide";

import styled from "styled-components";
import colors from "../helpers/styles/colors";
import spacing from "../helpers/styles/spacing";

const TrustPilotContainer = styled.div`
  background-color: ${colors.smokyWhite};
  padding: ${spacing.gutter50} ${spacing.gutter20};
`;

const TrustPilotContainerWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;

const TrustPilotPanel = () => {
  return (
    <TrustPilotContainer>
      <TrustPilotContainerWrapper>
        <TrustPilotScore />
        <TrustPilotSlide />
      </TrustPilotContainerWrapper>
    </TrustPilotContainer>
  );
};

export default TrustPilotPanel;
