import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import typography from "../helpers/styles/typography";
import spacing from "../helpers/styles/spacing";
import breakpoints from "../helpers/styles/breakpoints";

const SideBySideListContainer = styled.div`
  position: relative;
  padding: ${spacing.gutter40};
`;

const SideBySideListTopBorder = styled.div`
  background: url("/img/white-top.svg") no-repeat;
  width: 100%;
  position: absolute;
  top: -57px;
  height: 100px;
  left: 0;
  background-size: cover;
`;

const SideBySideListContainerWrapper = styled.div`
  position: relative;
  max-width: 1300px;
  margin: 0 auto;
  text-align: center;
`;

const SideBySideListTitle = styled.h2`
  margin-top: ${spacing.gutter50};
  margin-bottom: 70px;
  font: ${typography.h2};

  ${({ showTitleOnMobile }) => !showTitleOnMobile && `display: none;`}
  @media ${breakpoints.tablet} {
    display: block;
  }
`;

const biggerMiddleIconStyling = `
.item1 {
  img {
    @media ${breakpoints.tablet} {
      width: 127px;
      height: 149px;
    }
  }
}
`;

const sameSizeIconsStyling = `
.side-by-side-list-item {
  img {
    @media ${breakpoints.tablet} {
      width: 127px;
      height: 149px;
        }
      }
    }
  }`;

const SideBySideListItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  gap: 25px;

  .side-by-side-list-item {
    flex-grow: 1;
    text-align: center;
    max-width: 280px;
    margin-bottom: ${spacing.gutter50};
    padding: 0;
    padding: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    @media ${breakpoints.tablet} {
      flex-basis: 30%;
    }

    img {
      width: 91px;
      height: 91px;
      object-fit: contain;
    }

    .subtitle {
      display: block;
      font: ${typography.h3Desktop};
      margin-bottom: ${spacing.gutter10};
    }

    p {
      font: ${typography.contentSmall};
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  ${(props) => {
    if (props.isBiggerMiddleIcon) {
      return biggerMiddleIconStyling;
    } else {
      return sameSizeIconsStyling;
    }
  }}
`;

const SideBySideList = ({ title, list, isBiggerMiddleIcon, showTitleOnMobile }) => {
  const listItems = list.map((listItem, index) => (
    <div key={index} className={`side-by-side-list-item item${index}`}>
      {listItem.link && (
        <a href={listItem.link} className={`side-by-side-list-item item${index}`}>
          <img src={listItem.image.publicURL} alt={listItem.image.name} />
          {listItem.heading && <h3 className="subtitle">{listItem.heading}</h3>}
        </a>
      )}
      {!listItem.link && (
        <>
          <img src={listItem.image.publicURL} alt={listItem.image.name} />
          {listItem.heading && <h3 className="subtitle">{listItem.heading}</h3>}
        </>
      )}
      {listItem.description && <p>{listItem.description}</p>}
    </div>
  ));

  return (
    <SideBySideListContainer>
      <SideBySideListTopBorder></SideBySideListTopBorder>
      <SideBySideListContainerWrapper>
        <SideBySideListTitle showTitleOnMobile={showTitleOnMobile}>{title}</SideBySideListTitle>
        <SideBySideListItems isBiggerMiddleIcon={isBiggerMiddleIcon}>
          {listItems}
        </SideBySideListItems>
      </SideBySideListContainerWrapper>
    </SideBySideListContainer>
  );
};

SideBySideList.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      heading: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  isBiggerMiddleIcon: PropTypes.bool,
  showTitleOnMobile: PropTypes.bool,
};

export default SideBySideList;
