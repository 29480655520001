import styled from "styled-components";
import breakpoints from "../helpers/styles/breakpoints";

const GUTTERS_X = 0.25;

export const PageContainer = styled.div`
  margin: 0rem auto;
  max-width: calc(100vw - ${2 * GUTTERS_X}rem);

  @media ${breakpoints.mobileM} {
    max-width: calc(100vw - ${8 * GUTTERS_X}rem);
  }

  @media ${breakpoints.minWidth960px} {
    max-width: 940px;
  }
`;
