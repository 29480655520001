import { css } from "styled-components";
import colors from "./colors";
import typography from "./typography";
export const buttonStyle = () => css`
  background: ${colors.dustyBlue};
  color: ${colors.white};
  border-radius: 1rem;
  display: inline-block;
  padding: 0 0.8rem;
  text-decoration: none;
  font: ${typography.buttonContent};
`;
