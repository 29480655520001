import * as React from "react";
import styled from "styled-components";
import breakpoints from "../helpers/styles/breakpoints";
import typography from "../helpers/styles/typography";
import { ButtonBlueWhite } from "./ButtonLinks";

const EstimateContainer = styled.section`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  max-width: 940px;
  margin: 32px auto 24px auto;
  padding: 44px 48px;
  border: 0px solid #ffffff;
  border-radius: 24px;
  font-family: "Montserrat";
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(/img/winter-bg.png);
  background-position-x: center;
  background-size: cover;
  color: #ffffff;

  @media ${breakpoints.maxWidth650px} {
    height: unset;
    max-width: 500px;
    flex-direction: column-reverse;
    padding-top: 110px;
    margin: 74px 16px 24px 16px;
  }
`;

const EstimateHeader = styled.h2`
  font: ${typography.h2};
  line-height: 1;
`;

const TextContainer = styled.div`
  flex-basis: 60%;
  @media ${breakpoints.maxWidth650px} {
    flex-basis: 100%;
  }
`;

const ResultsImgDesktop = styled.img`
  width: 288px;
  position: absolute;
  right: 15px;
  @media ${breakpoints.minWidth890px} {
    width: 320px;
  }
  @media ${breakpoints.maxWidth650px} {
    display: none;
  }
`;

const ResultsImgMobile = styled.img`
  --width: 134px;
  display: none;
  width: var(--width);
  @media ${breakpoints.maxWidth650px} {
    display: block;
    position: absolute;
    top: -65px;
    left: calc(50% - calc(var(--width) / 2));
  }
`;

const Subhead = styled.p`
  font: ${typography.subHeader};
  line-height: 32px;
`;

export const GetEstimate = ({ info }) => {
  const { heading, subHeading, button, finePrint } = info || {};
  return (
    <EstimateContainer>
      <TextContainer>
        <EstimateHeader>{heading}</EstimateHeader>
        <Subhead>
          {subHeading}
        </Subhead>
        <ButtonBlueWhite href={button.link}>{button.text}</ButtonBlueWhite>
        <p>{finePrint}</p>
      </TextContainer>
      <ResultsImgDesktop
        src="/img/winter-calc-result.webp"
        alt="some example results from the winter calculator"
      />
      <ResultsImgMobile
        src="/img/winter-calc-single-result.webp"
        alt="some example results from the winter calculator"
      />
    </EstimateContainer>
  );
};
