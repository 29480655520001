import React from "react";
import styled from "styled-components";
import breakpoints from "../helpers/styles/breakpoints";
import typography from "../helpers/styles/typography";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  margin-bottom: 20px;
  @media ${breakpoints.maxLaptop} {
    margin: auto;
  }
`;
const ColumnContainer = styled.div`
  display: flex;
  > * {
    &:first-child {
      margin-right: 18px;
    }
  }
  @media ${breakpoints.maxLaptop} {
    justify-content: center;
  }
`;
const Title = styled.div`
  display: flex;
  font: ${typography.h3Desktop};
  margin-bottom: 25px;
  @media ${breakpoints.maxLaptop} {
    justify-content: center;
  }
`;

const StoreIcon = styled.a`
  cursor: pointer;
`;

const AppStoreIcons = ({ title, list, iconSize }) => {
  return (
    <Container>
      {title && <Title data-testid="app-store-title">{title}</Title>}
      <ColumnContainer>
        {list.map((item) => (
          <StoreIcon key={item.heading} href={item.link} data-testid={item.image.name}>
            <img width={iconSize} src={item.image.publicURL} alt={item.image.name} />
          </StoreIcon>
        ))}
      </ColumnContainer>
    </Container>
  );
};

export default AppStoreIcons;
