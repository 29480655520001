import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import BackgroundImage from "gatsby-background-image";

import typography from "../helpers/styles/typography";
import spacing from "../helpers/styles/spacing";
import breakpoints from "../helpers/styles/breakpoints";
import colors from "../helpers/styles/colors";

const PAYGPanelContainer = styled.div`
  position: relative;
`;

const PanelImageContainer = styled.div`
  position: relative;
  min-height: 350px;
  @media ${breakpoints.tablet} {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

const PanelImageTopBorder = styled.div`
  background: url("/img/white-bottom.svg") no-repeat bottom center;
  width: 100%;
  position: absolute;
  top: -50px;
  z-index: 3;
  height: 92px;
  background-size: cover;
  @media ${breakpoints.laptopL} {
    width: 109%;
  }
  @media ${breakpoints.tablet} {
    top: -10px;
  }
`;

const PanelImage = styled(BackgroundImage)`
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 270px;
  position: absolute !important;
  top: 0;
  z-index: 1;
  @media ${breakpoints.tablet} {
    right: 0;
    width: 53%;
  }
`;

const PanelContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  background: ${colors.black};
  color: #fff;
  margin-top: -45px;
  @media ${breakpoints.mobileL} {
    background: url("/img/payg-specialists-black.svg") no-repeat;
    background-size: 140%;
    background-position: top left;
  }
  @media ${breakpoints.tablet} {
    margin-top: 0;
    background-size: cover;
    max-width: 95%;
    background-position: top right;
  }
  @media ${breakpoints.mobileX} {
    max-width: 90%;
  }
  @media ${breakpoints.laptop} {
    max-width: 74%;
    background-position: bottom right;
  }
`;

const PanelContentHolder = styled.div`
  @media ${breakpoints.laptop} {
    padding: 4px 0px;
    max-width: 1000px;
    margin: 0 auto;
  }
`;

const PanelContent = styled.div`
  margin: ${spacing.gutter30};
  display: inline-block;
  @media ${breakpoints.tablet} {
    width: 58%;
    max-width: 500px;
    margin: 20px 10%;
  }
  h2 {
    font: ${typography.h3Desktop};
    margin-top: ${spacing.gutter25};
    @media ${breakpoints.tablet} {
      margin-top: ${spacing.gutter80};
    }
  }
  p {
    font: ${typography.contentSmall};
    margin-bottom: ${spacing.gutter10};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const PAYGPanel = ({ mainpitchPanel }) => {
  const imageData = mainpitchPanel.image.childImageSharp?.fluid;

  return (
    <PAYGPanelContainer>
      <PanelImageContainer>
        <PanelImageTopBorder></PanelImageTopBorder>
        <PanelImage Tag="div" className={"gatsby-bg"} fluid={imageData}></PanelImage>
      </PanelImageContainer>
      <PanelContentWrapper>
        <PanelContentHolder>
          <PanelContent>
            <h2>{mainpitchPanel.title}</h2>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw, rehypeSanitize]}
              children={mainpitchPanel.description}
            />
          </PanelContent>
        </PanelContentHolder>
      </PanelContentWrapper>
    </PAYGPanelContainer>
  );
};

PAYGPanel.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PAYGPanel;
