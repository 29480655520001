import React from "react";
import PropTypes from "prop-types";

import SideBySideList from "./SideBySideList";

const BenefitsList = ({ mainpitchBenefitsList }) => {
  return (
    <SideBySideList
      list={mainpitchBenefitsList.blurbs}
      title={mainpitchBenefitsList.title}
      showTitleOnMobile={true}
      isBiggerMiddleIcon
    />
  );
};

BenefitsList.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  }),
};

export default BenefitsList;
