/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";

const TrustPilotScore = () => {
  const ref = React.useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
    if (!scriptLoaded) {
      return;
    }
  }, [scriptLoaded]);

  const handleChangeClientState = (newState, addedTags) => {
    if (addedTags && addedTags.scriptTags) {
      const foundScript = addedTags.scriptTags.find(
        ({ src }) =>
          src ===
          "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      );
      if (foundScript) {
        foundScript.addEventListener("load", () => setScriptLoaded(true), {
          once: true
        });
      }
    }
  };

  return (
    <>
      <Helmet onChangeClientState={handleChangeClientState}>
        <script
          src={
            "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          }
        />
      </Helmet>
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="53aa8807dec7e10d38f59f32"
        data-businessunit-id="59c9f3960000ff0005ac0e92"
        data-style-height="150px"
        data-style-width="100%"
        data-theme="light"
        data-tags="SelectedReview"
      >
        <a
          href="https://uk.trustpilot.com/review/boostpower.co.uk"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </>
  );
};

export default TrustPilotScore;
