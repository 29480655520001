import React from "react";
import styled from "styled-components";
import colors from "../helpers/styles/colors";
import breakpoints from "../helpers/styles/breakpoints";
import { ButtonWhiteBlue } from "./../components/ButtonLinks";
import typography from "../helpers/styles/typography";
import { hasWindow } from "../helpers/window";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";

const StrugglingToPayBannerSection = styled.section`
  max-width: 940px;
  margin: 60px auto 0px auto;
  border: 1px lightgrey solid;
  border-top-color: ${colors.brandYellow};
  border-top-width: 10px;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  @media ${breakpoints.maxWidth890px} {
    margin: 20px 16px 0px 16px;
  }
`;

const StrugglingToPayBannerTitle = styled.h3`
  width: fit-content;
  display: flex;
  font: ${typography.h3Desktop};
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  margin: 0px;
`;

const StrugglingToPayBannerBody = styled.p`
  font: ${typography.contentSmall};
  margin: 16px 0px;
  line-height: 22px;
`;

const StrugglingToPayBannerContentWrapper = styled.div`
  padding: 24px;
`;

const StrugglingToPayButton = styled(ButtonWhiteBlue)`
  margin-top: 0px;
  margin-bottom: 12px;
`;

const openChatHandler = () => {
  hasWindow() && window.genesysOpen();
};

const StrugglingToPayBanner = ({info}) => {
  const {heading, description, button} = info || {};
  return (
    <StrugglingToPayBannerSection>
      <StrugglingToPayBannerContentWrapper>
        <StrugglingToPayBannerTitle>{heading}</StrugglingToPayBannerTitle>
        <StrugglingToPayBannerBody>
        {/* {description} */}
        <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]} children={description}></ReactMarkdown>
        </StrugglingToPayBannerBody>

        <StrugglingToPayButton onClick={openChatHandler}>{button}</StrugglingToPayButton>
      </StrugglingToPayBannerContentWrapper>
    </StrugglingToPayBannerSection>
  );
};
export default StrugglingToPayBanner;
