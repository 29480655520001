import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";

import { GetTheApp } from "../components/GetTheApp";

import styled from "styled-components";
import colors from "../helpers/styles/colors";
import typography from "../helpers/styles/typography";
import spacing from "../helpers/styles/spacing";
import breakpoints from "../helpers/styles/breakpoints";
import AppStores from "../components/AppStores";

const maxWidth = "1150px";

const PAYGListContainer = styled.div`
  background: ${colors.lightBlueGradient};
  padding-bottom: ${spacing.gutter20};
`;

const PAYGListContainerBorderTop = styled.div`
  // background: url("/img/blue-base.svg") no-repeat bottom center;
  background-size: cover;
  width: 1440px;
  height: 330px;
  left: -5px;
  position: relative;
  @media ${breakpoints.laptopL} {
    width: 101%;
  }
`;

const PAYGListContainerWrapper = styled.div`
  position: relative;
  max-width: 1300px;
  margin: 0 auto;
  text-align: center;
  margin-top: -215px;
  @media ${breakpoints.tablet} {
  }
`;

const PAYGListImageWrapper = styled.div`
  width: 182px;
  height: 328px;
  margin: 0 auto;
  position: absolute;
  top: -45px;
  left: 0;
  right: 0;
  z-index: 1;

  @media ${breakpoints.tablet} {
    min-width: 280px;
    margin-top: 0;
    top: ${spacing.gutter80};
    left: -6px;
    right: auto;
  }

  @media (min-width: ${maxWidth}) {
    min-width: 320px;
    margin-top: 0;
    top: ${spacing.gutter80};
    left: -${spacing.gutter80};
    right: auto;
  }
`;

const PAYGListContentShade = styled.div`
  background: url("/img/PAYG+_bg_mobile.svg") no-repeat center center;
  background-size: cover;
  width: 100%;
  position: relative;
  transform: rotate(0);
  @media ${breakpoints.tablet} {
    background: url("/img/PAYG+_bg_2.svg") no-repeat center center;
    background-size: cover;
  }
`;

const PAYListContentWrapper = styled.div`
  position: relative;
  margin: 0 auto;

  @media ${breakpoints.tablet} {
    width: 100%;
    max-width: 1100px;
    padding: 0;
    margin-top: 0;
  }
`;

const PAYGListContent = styled.div`
  position: relative;
  text-align: left;
  padding: 0 ${spacing.gutter25};
  padding-top: 300px;
  padding-bottom: ${spacing.gutter50};

  @media ${breakpoints.tablet} {
    width: 100%;
    max-width: 600px;
    margin-left: 30%;
    padding: 105px 0;
    top: -25px;
  }
  @media ${breakpoints.tabletTo890px} {
    max-width: 450px;
    margin-left: 38%;
  }
  h2 {
    font: ${typography.subHeader};
    @media ${breakpoints.tablet} {
      font: ${typography.h2};
      line-height: 1 !important;
    }
  }

  .subtitle {
    font-weight: normal;
    @media ${breakpoints.tablet} {
      font: ${typography.subHeader};
      margin: 0;
    }
  }
`;

const PAYGListContentItems = styled.div`
  ul {
    list-style-type: none;
    position: relative;
    padding-left: 0;
    margin: 25px 0;
    li {
      margin-bottom: ${spacing.gutter15};
      font: ${typography.body};
      position: relative;
      @media ${breakpoints.tablet} {
        font: ${typography.bodyDesktop};
      }
      .image-holder {
        display: inline-block;
        vertical-align: middle;
        width: 51px;
        height: 51px;
        text-align: center;
        margin-right: ${spacing.gutter20};
      }
      img {
        display: inline-block;
        vertical-align: middle;
        @media ${breakpoints.maxMobile} {
        }
      }
      p {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 71px);
        font: ${typography.contentSmall};
        @media ${breakpoints.maxMobile} {
        }
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  padding: ${spacing.gutter30} 0;
  text-align: center;
  @media ${breakpoints.tablet} {
    padding: ${spacing.gutter30} 0 0;
    text-align: left;
    margin-left: ${spacing.gutter80};
  }
`;

const PAYGTraditionalContentWrapper = styled.div`
  max-width: 650px;
  margin: 40px auto;
  margin-top: ${spacing.gutter50};
  padding: 0 ${spacing.gutter20};
  padding-bottom: ${spacing.gutter50};
  .subtitle {
    font-size: 22px;
  }
  @media ${breakpoints.tablet} {
    padding-bottom: ${spacing.gutter80};
  }
`;

const PAYGList = ({ mainpitchPAYGList, boostAppList }) => {
  const { downloadText, appsList } = boostAppList;

  const listItems = mainpitchPAYGList.blurbs.map((listItem, index) => (
    <li key={index} className={`list-item` + index}>
      <div className="image-holder">
        <img
          src={listItem.image.publicURL}
          alt={listItem.image.name}
          className={`list-item-img` + index}
        />
      </div>
      <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]} children={listItem.text} />
    </li>
  ));

  return (
    <PAYGListContainer>
      <PAYGListContainerBorderTop></PAYGListContainerBorderTop>
      <PAYGListContainerWrapper>
        <PAYListContentWrapper>
          <PAYGListContentShade>
            <PAYGListImageWrapper>
              <Img
                fluid={mainpitchPAYGList.image.childImageSharp?.fluid}
                alt={mainpitchPAYGList.image.name}
              />
            </PAYGListImageWrapper>
            <PAYGListContent>
              <h2>{mainpitchPAYGList.title}</h2>
              <h2>{mainpitchPAYGList.subtitle}</h2>
              <h3 className="subtitle">{mainpitchPAYGList.description}</h3>
              <PAYGListContentItems>
                <ul>{listItems}</ul>
              </PAYGListContentItems>
              <p>
                <i>{mainpitchPAYGList.text}</i>
              </p>
              <ButtonWrapper>
                <AppStores title={downloadText} list={appsList} iconSize={125} />
              </ButtonWrapper>
            </PAYGListContent>
          </PAYGListContentShade>
        </PAYListContentWrapper>

        <PAYGTraditionalContentWrapper>
          <GetTheApp />
        </PAYGTraditionalContentWrapper>
      </PAYGListContainerWrapper>
    </PAYGListContainer>
  );
};

PAYGList.propTypes = {
  mainpitchPAYGList: PropTypes.object,
  boostAppList: PropTypes.object,
};

export default PAYGList;
